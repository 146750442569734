<template>
  <b-navbar-nav class="ml-auto">
    <b-nav-item to="/">Dashboard</b-nav-item>
    <b-nav-item to="/movies">Movies</b-nav-item>
    <b-nav-item to="/shows">Shows</b-nav-item>
    <b-nav-item to="/thrillers">Thrillers</b-nav-item>
    <b-nav-item to="/upcomings">Upcomings</b-nav-item>
    <b-nav-item to="/popups">Popups</b-nav-item>
    <b-nav-item to="/tickets">Tickets</b-nav-item>
    <b-nav-item @click="logOut">Logout</b-nav-item>
  </b-navbar-nav>
</template>

<script>

export default {
  methods: {
    logOut() {
      this.$store.dispatch("user", null);
      this.$store.dispatch("isLoggedIn", false);
      localStorage.clear();
      this.$router.push("/login");
    }
  }
};
</script>
<style scoped>
.mobile-only {
  display: none;
}

@media (max-width: 991px) {
  .mobile-only {
    display: inline-block;
  }
}
</style>
